<!--
    * @Author: your name
    * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-14 17:10:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
    * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
    * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
   -->
<template>
  <div class="news">
    <div id="news"></div>
    <div class="wrapper">
      <video class="video" autoplay="" muted="" playsinline="" loop="" src="/video/center.mp4"></video>
      <section class="content">
        <p class="title text-linear text-bold text-green">{{ info.title }}</p>
      </section>
    </div>
      <div class="center_main">
        <!-- 轮bo -->
        <section class="sub_content">
          <div class="title text-green text-bold">{{info.feature.title}}</div>
          <el-carousel trigger="click" height="400px" v-if="isMobile">
            <el-carousel-item class="swiper-slide" v-for="(item,index) in info.feature.list" :key="index">
              <a href="javascript:void(0)">
                <div class="image" :style="{background: `url('${ item.icon}') no-repeat center/100% auto`}" >
                  <div>
                    <div class="title">
                      <span class="text-icon"><img src="/images/SVG/star.svg"/><span class="text-bold">{{item.count}}</span></span>
                      <img src="/images/svg/heart_off.svg"/>
                    </div>
                  </div>
                  </div>
                <div class="content">
                  <p class="title text-icon text-bold"><img :src="item.sm_icon"/>{{item.title}}</p>
                  <p class="desc ">
                    <span class="text-icon text-green"><img src="/images/svg/see.svg"/>{{item.eyes}}</span>
                    <span class="text-icon text-green"><img src="/images/svg/heart.svg"/>{{item.actions}}</span>
                  </p>
                </div>
              </a>
            </el-carousel-item>
          </el-carousel>
          <div class="content safe_content swiper-container" v-else>
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in info.feature.list" :key="index">
                <a href="javascript:void(0)">
                  <div class="image" :style="{background: `url('${ item.icon}') no-repeat center/100% auto`}">
                    <div>
                      <div class="title">
                        <span class="text-icon" ><img src="/images/svg/star.svg"/><span class="text-bold">{{item.count}}</span> </span>
                        <img src="/images/svg/heart_off.svg"/>
                      </div>
                    </div>
                  </div>
                  <div class="content">
                    <p class="title text-icon text-bold"><img :src="item.sm_icon"/>{{item.title}}</p>
                    <p class="desc">
                      <span class="text-icon text-green"><img src="/images/svg/see.svg"/>{{item.eyes}}</span>
                      <span class="text-icon text-green"><img src="/images/svg/heart.svg"/>{{item.actions}}</span>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <!-- guilds -->
        <section class="sub_content">
          <div class="title text-green text-bold">{{ info.guilds.title }}</div>
          <div class="content safe_content guilds">
            <div class="guilds_item" v-for="(items, i) in info.guilds.list" :key="i">
              <div class="card_img" :style="{background: `url('${ items.image}') no-repeat center/100% auto`}">
                <p class="title text-bold">{{items.title}}</p>
              </div>
              <div class="card_content">
                <p class="desc text-green">{{items.desc}}</p>
                <div class="subscribe">
                  <div class="avater" v-show="isMobile?index<3 : index<5" v-for="(item, index) in items.avater" :key="i+'-'+index" :style="{background: `url('${ item.avater}') no-repeat center/100% auto`}"></div>
                  <!-- <el-tooltip class="tooltip" effect="dark" placement="top-start">
                    <div class="avater">+</div>
                    <div class="subscribe" slot="content">
                      <div class="avater" v-for="(item, index) in items.avater" :key="i+'-'+index" :style="{background: `url('${ item.avater}') no-repeat center/100% auto`}"></div>
                    </div>
                  </el-tooltip> -->
                </div>
                <div class="grid">
                  <div><p class="label text-gray">{{items.b_title1}}</p><p>{{items.b_title_center1}}</p></div>
                  <div><p class="label text-gray">{{items.b_title2}}</p><p>{{items.b_title_center2}}</p></div>
                  <div><p class="label text-gray">{{items.b_title3}}</p><p>{{items.b_title_center3}}</p></div>
                </div>
              </div>
              <div class="card_jue">
              </div>
            </div>
          </div>
        </section>
        <!-- igod -->
        <section class="sub_content">
          <div class="title text-green text-bold">{{ info.igos.title }}</div>
          <div class="content safe_content igod">
            <div class="igod_item" v-for="(items, i) in info.igos.list" :key="i">
              <div class="card_img" :style="{background: `url('${ items.icon}') no-repeat center/100% auto`}">
                <div class="title text-icon">
                  <img src="/images/svg/friends.png" />
                  <span>PUBLIC</span>
                </div>
              </div>
              <div class="card_content">
                <p class="text-bold">{{ items.title }}</p>
                <div>
                  <p class="text-gray">{{ items.desc }}</p>
                  <p class="text-green">TBA</p>
                </div>
              </div>
              <div class="card_jue">
              </div>
            </div>
          </div>
        </section>
      </div> 
    <!-- <Footer /> -->
  </div>
</template>
<script>
import Swiper from "swiper";
// import Footer from "@/components/footer"; //页脚

export default {
  components: {
    // Footer,
  },
  created() {
    this.$watch('$store.state.block', (newVal, oldVal) => {
      this.info = newVal.center
    },{immediate:true,deep:true})
  },
 
  mounted() {
    if (!this.isMobile) {
      new Swiper(".swiper-container", {
        pagination: ".swiper-pagination",
        loop: true,
        slidesPerView: 5,
        autoplay: 2000,
      });
    }
  },
  data() {
    return {
      info: {},
    }
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  methods: {},
};
</script>
<style lang="less">
/deep/.tooltip .subscribe{ display:flex; grid-column-gap: 10px; width: 300px; height: 200px; }
/deep/.tooltip .avater{ flex-shrink: 0; width: 32px; height: 32px; border-radius: 50%; background: #393C5E; display: inline-flex; justify-content: center; align-items: center; margin: 20px 0;}
</style>
<style lang="less" scoped>
.sub_content>.title{ font-size: 60px; text-align: center;border-bottom: 2px solid; border-image:linear-gradient(270deg, #131313 0%, #C9FF00 51%, #131313 100%) 4; border-width: 0 0 0 100;}
.sub_content>.content{ padding: 60px 0 120px; }
.center_main{ background: linear-gradient(180deg, #050505 0%, #1B1D27 51%, #131313 100%); }
.game_wrapper{ background: url('/images/game_bg.png') ;}
.safe_content{ min-height: unset; height: auto; max-width:1680px; }


/*第一块 */
.wrapper{ position: relative; top: 0; left: 0; overflow: hidden;}
.wrapper video{ position: absolute;top: -35%; left: 0; z-index: 10; width: 100%; height: auto; }
.wrapper .content{ height: 350px; padding-top: 80px; z-index: 20; position: relative; background: linear-gradient(180deg, rgba(19,19,19,0) 80%, #050505 100%); display: flex; flex-direction: column; justify-content: center; align-items: center;}
.wrapper .content>.title{ font-size: 64px; }
.btn{ padding: 30px 0; }
.btn>button { cursor:pointer; width: 360px; height: 80px; background: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); color: #050020; font-size: 28px; }
.btn>button:hover{ box-shadow: 0px 0px 20px 1px #C9FF00; }
.sdk_main{background: linear-gradient(180deg, #050505 0%, #1B1D27 51%, #131313 100%); }
.sdk_main .sub_content{ display: flex; flex-direction: column; grid-row-gap: 30px; font-size: 18px; }
.sub_content ul{ list-style: disc; padding-left: 20px; }

/* Feature */
.swiper-container {width: 100%;}
.swiper-slide {height: 500px;font-size: 18px;display: flex;justify-content: space-between;align-items: center;flex-direction: column;}
.swiper-slide a { width: 320px;aspect-ratio: 32/50; overflow: hidden; background-color: #212139;text-decoration:none; display: flex; justify-content: flex-start; flex-direction: column;}
.swiper-slide a>.image {width: 100%; aspect-ratio: 32/40; padding: 10px; font-size: 16px; line-height: 1; position: relative; top: 0; left: 0; } 
.swiper-slide a>.image .title{ display: flex; justify-content: space-between;} 
.swiper-slide a>.image .title img{ width: 20px; height: 20px; } 
.swiper-slide a>.image>div{ padding: 10px; position:absolute; top: 0; left: 0; background: linear-gradient(180deg, #08080A 0%, rgba(84,84,84,0) 100%); height: 100%; width: 100%;} 
.swiper-slide a>.image>div:hover{background:unset;} 
.swiper-slide a>.image .text-icon{ align-items:flex-end;}
.swiper-slide a>.content{width: 100%;display: flex; flex-direction: column; justify-content: center; flex:1; padding:0 20px;grid-row-gap: 5px;}
.swiper-slide a>.content .title{font-size: 24px;}
.swiper-slide a>.content .title img{ height: 24px; width: 24px; margin-right: 10px;}
.swiper-slide a>.content .desc{display: flex; align-items: center; grid-column-gap: 20px;font-size: 18px;}
.swiper-slide a>.content .desc img{ height: 18px; width: 18px;}

/* guilds */
.guilds{display: grid; grid-template-columns: repeat(5,1fr); grid-gap:20px;}
.guilds .guilds_item{background: #212139; position: relative; top: 0; left: 0; overflow: hidden; padding-bottom: 40px;}
.guilds_item .card_img{ width: 100%; aspect-ratio: 320/168; display: flex; align-items: flex-end; }
.guilds_item .card_img .title{ background: linear-gradient(180deg, rgba(33, 33, 57, 0) 0%, rgba(33, 33, 57, 1) 100%); width: 100%;font-size: 24px; line-height: 1.5; padding: 0 10px;} 
.guilds_item .card_content{ padding: 0 10px; }
.guilds_item .card_content .desc{ font-size: 18px; }
.guilds_item .card_content .subscribe{ display:flex; grid-column-gap: 10px; }
.guilds_item .card_content .avater{ flex-shrink: 0; width: 32px; height: 32px; border-radius: 50%; background: #393C5E; display: inline-flex; justify-content: center; align-items: center; margin: 20px 0;}


.guilds_item .card_content .grid{ display:flex; justify-content: space-between; font-size: 18px; grid-row-gap:30px; flex-wrap: wrap; }
.guilds_item .card_content .grid .label{ color: #B7B7D2; }
.guilds_item .card_jue{ background-color: #171726; height: 16px; width: 16px; position: absolute; right: -8px; bottom: -8px;  transform:rotate(45deg) }

/* Upcoming IGOS */
.igod{display: grid; grid-template-columns: repeat(5,1fr); grid-gap:20px;}
.igod .igod_item{background: #212139; position: relative; top: 0; left: 0; overflow: hidden;}
.igod_item .card_img{ width: 100%; aspect-ratio: 320/168;  background: url('/images/demoImage/蒙版组 11(1).png') no-repeat center/100% auto ; display: flex; align-items: flex-start; }
.igod_item .card_img .title{ font-size: unset; background: linear-gradient(90deg, #131313 0%, rgba(19,19,19,0) 50% ); width: 100%;font-size: 12px; line-height: 2.5; padding: 0 10px;} 
.igod_item .card_img .title img{ width: 16px; height: 16px; } 
.igod_item .card_content{ text-align: center; font-size: 14px; }
.igod_item .card_content>p{ padding:0 10px; height: 64px; border-bottom: 1px solid #2E2E4E; line-height: 32px; line-height: 1.3; display: flex; align-items: center; justify-content: center; }
.igod_item .card_content>div{ padding:10px 10px; }

.igod_item .card_content .text-green{ font-size: 18px; }
.igod_item .card_jue{ background-color: #171726; height: 16px; width: 16px; position: absolute; right: -8px; bottom: -8px;  transform:rotate(45deg) }



@media screen and (max-width: 1100px) {
  .wrapper .content{ height: 2.5rem; line-height: 1.3; }
 .app .wrapper .video{ display: none; }

 .app .wrapper { background: url('/video/center.png') no-repeat center/100% auto; text-align: center;}
 .app .wrapper .content>.title{ font-size: 0.4rem;}
 .app .wrapper .content>.title{ font-size: 0.4rem;}
  .app .sub_content > .title{ font-size: 0.32rem;}
  .app .sub_content > .content{ padding: 0.3rem  0.2rem  0.6rem;}



  

  /* Feature */
  /deep/.el-carousel button{ min-width: unset; }
  /deep/.el-carousel__container{ overflow: hidden; margin: 20px 0;}

  .swiper-slide a>.content .title{font-size: 0.2rem;}
  .swiper-slide a>.content .title img{ height: 0.2rem; width: 0.2rem; margin-right: 10px;}
  .swiper-slide a>.content .desc{display: flex; align-items: center; grid-column-gap: 20px;font-size: 0.16rem;}
  .swiper-slide a>.content .desc img{ height: 0.16rem; width: 0.16rem;}
  /deep/.el-carousel .el-carousel__indicators { display: none; }
  .app .swiper-slide{position: absolute;}
  .swiper-slide a {width: 250px; }

  .igod{ grid-template-columns: repeat(2,1fr); grid-gap:0.1rem;}
  .igod_item .card_content>p{ font-size: 0.14rem;}
  .igod_item .card_content>div { font-size: 0.12rem;}

  .igod_item .card_content .text-green{ font-size: 0.14rem;}

  .guilds{ grid-template-columns: repeat(2,1fr); grid-gap:0.1rem;}
  .guilds .card_img .title{ font-size: 0.14rem;}
  .guilds .guilds_item{ padding-bottom: 0.15rem;}
  .guilds_item .card_content .grid{font-size: 0.12rem;grid-row-gap: 10px;}
  .guilds_item .card_content .desc{ font-size: 0.12rem; line-height: 1.3;}
  .guilds_item .card_content .avater{ margin: 0.1rem 0; width: 0.28rem; height: 0.28rem; }

}
</style>
