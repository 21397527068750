<template>
  <div class="header">
    <div class="header-content">
      <img class="logo" :src="info.navLogo" @click="goAnchor()"  alt="revoland"/>
      <div class="menu">
        <div class="menu-item" v-for="(item, i) in info.navList" :key="i" >
          <a href="javascript:;" @click="link(item.link)"> {{item.title}}
          <sup v-if="item.title === 'R-DAO'" class="item-sup">MAX</sup>
          </a>
        </div>
      </div>
      <!-- 操作 -->
      <div class="handle">
        <el-dropdown class="language"  @command="changeLanguage">
          <div class="language-btn">
            {{ $t("common.selectLanguage") }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, key) in languages"
              :key="key"
              :command="item.key"
            >
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <i class="drawer el-icon-s-fold" @click="openDrawer"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 语音列表
      info:null,
      languages: [
        { title: "繁體中文", key: "zh-cn" },
        { title: "English", key: "en" },
      ],
      // 默认中文
      classActive: false,
      drawer: false,
      direction: 'rtl',
    };
  },
  created() {
    this.$watch('$store.state.block', (newVal) => {
      this.info = newVal.drawer
    },{immediate:true})
    // 箭筒滚动
    window.addEventListener('scroll',function(){
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let header = document.getElementsByClassName('header')[0]
      if(scrollTop>100){ 
        header.style.background = 'RGBA(19, 19, 19, 0.7)';
      }else{
        header.style.background = '';
      }
    },false);

  },
  mounted(){},
  methods: {
    // 下拉框选择语言
    changeLanguage(item) {    
      if (item === this.$store.getters.getLang) {
          return;
      }
      this.$store.state.lang = item;
      this.$i18n.locale = item;
    },
    openDrawer() {
      this.$store.commit("updateDrawer");
    },
    link(http){ 
      const role = new RegExp('http');
      console.log(http,role.test(http))
      // return false
      if(role.test(http)){ 
        // 外联跳转
        window.location.href = http
      }else{ 
        this.$router.push(http);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-menu{ background-color: RGBA(56, 69, 86, 0.5); border: 0;}
.el-dropdown-menu__item{ color: #fff;}
.el-dropdown-menu__item:hover{ color: #fff; background-color: unset;}
.el-dropdown-menu .popper__arrow{ border-bottom-color: RGBA(56, 69, 86, 0.5) !important;}
.el-dropdown-menu .popper__arrow::after{ border-bottom-color: RGBA(56, 69, 86, 0.5) !important;}

.header {background-size: cover;height: 80px; position: fixed; top: 0;top: 0; left:0; color: #fff;font-size: 18px;width: 100%;z-index: 1024;}

.header .header-content { min-width: 1080px; display: flex;align-items: center;justify-content: space-between;height: 80px;width: 100%;margin: 0 auto;padding: 0 30px;}
.logo {width: auto;height: 40px;}
.drawer{display: none;}
.menu {display: flex;justify-content: space-around;align-items: center;width: 700px; color: #fff; margin-left:auto;}
.item-sup {
  margin-top: -4px;
  font-size: 12px;
}
a{color: #fff;}
a:hover{color: #C9FF00;}
a:hover .item-sup{color: #C9FF00;}
.handle {display: flex;align-items: center;}
.handle .language-btn{font-size: 18px; margin-right: 20px; color: #FEF71D; width: 90px; text-align: right;}


@media screen and (max-width: 1100px) {
  .app .header { height: 0.58rem;font-size: 0.1rem;}
  .app .header .header-content {height: 0.58rem;width: 100%;margin: 0 auto;padding: 0 0.2rem;min-width: unset;}
  .app .logo {width: auto;height: 0.24rem;}
  .app .menu {display: none;}
  .app .language{display: none;}
  .app .drawer{display: block; font-size: 0.2rem; margin-left: 0.2rem;}
  .app .handle .handle-btn {height: 0.3rem;font-size: 0.1rem;}
}
</style>
