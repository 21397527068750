/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-06 15:09:43
 * @LastEditTime: 2023-02-15 17:46:56
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
// const BASEURL="http://192.168.110.250:8025/index.php";//正式API网址
// const BASEURL="https://api-n.revoland.com/index.php";//正式API网址
const BASEURL="https://api.revoland.com/index.php";//正式API网址
// const BASEURL="http://revoguanwangtest_server.laobaby.club";//测试API网址
// const BASEURL="http://192.168.110.93:8086/";//测试API网址
const CDNURL="https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com";//CDN地址
const APIKEY="ladfdddfdfd"




export default({
    BASEURL:BASEURL,
    CDNURL:CDNURL,
    APIKEY:APIKEY
})