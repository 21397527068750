<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-03 18:27:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="partner border-line" ref="partner" >
    <div id="partner"></div>
    <div class="content safe_content"  >
      <div class="title text-green text-bold">{{info.title||"Partnerships"}}</div>
      <div class="tab_box">
        <div
          class="tab_item"
          v-for="(item, index) in info"
          :key="item.id"
          :class="[index == show ? 'text-bold active' : 'text-white']"

          @click="changeindex(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="partner_group"
        v-for="(items, index) in info"
        :key="items.id||index"
        v-show="index == show"
        :class="{'animation-slide-bottom':showfate}"
      >
        <div class="partner_item" v-for="item in items.logolinklist" :key="item.name">
          <a :href="item.url" target="_black">
            <img :src="item.imgUrl" :key="item.name" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { imgsPreloader } from '@/utils/imgPreloader/imgPreloader.js';
import { partner } from "@/utils/imgPreloader/config.js";
export default {
  data() {
    return {
      show: 0,
      showfate:false,
    };
  },
  props: {
    info: {
      type: [Array],
      default: function () {
          return [
          {name: 'INVESTORS',logolinklist:[
            {image: "Investors1.png",name: "Haskey Capital",outlink: "https://www.hashkey.com/",},
            {image: "Investors2.png",name: "One Block Capital Advisory",outlink: "https://www.oneblockcapital.com/",},
            {image: "Investors6.png",name: "Arcanum Capital	",outlink: "https://www.arcanum.capital/",},
            {image: "Investors4.png",name: "Linkvc",outlink: "http://www.linkvc.com/",},
            {image: "Investors5.png",name: "A.K.G Ventures",outlink: "http://www.akgvc.com/",},
            {image: "Investors3.png",name: "Atlas Capital",outlink: "https://www.atlascapital.org/",},
            {image: "Investors7.png",name: "Crypto Nord",outlink: "https://www.cryptonord.com/",},
          ]},
          {name:'PARTNERS',logolinklist:[
            {image: "PARTNERS4.png",name: "ICEBURGER",outlink: "http://www.iceburger.net/	",},
            {image: "PARTNERS5.png",name: "EARNATHON",outlink: "https://earnathon.com/",},
            {image: "PARTNERS11.png",name: "earn",outlink: "https://www.earn.games/",},
            {image: "PARTNERS3.png",name: "SIGMA",outlink: "https://www.sigma-global.com/en/	",},
            {image: "PARTNERS1.png",name: "SEAGM",outlink: "https://www.seagm.com/	",},
            {image: "PARTNERS2.png",name: "SEM9",outlink: "https://www.sem9.gg/	",},
            {image: "PARTNERS8.png",name: "SALES",outlink: "https://sales-and-ads.com/"},
            {image: "PARTNERS10.png",name: "daoverse",outlink: "https://daoverse.capital/",},
            {image: "PARTNERS6.png",name: "HUAWEI",outlink: "https://www.huawei.com/en/",},
            {image: "PARTNERS9.png",name: "ALAMEDA-RESEARCH",outlink: "https://www.alameda-research.com/",},
            {image: "PARTNERS7.png",name: "KHALASPAY",outlink: "https://khalaspay.net/",},
          ]},
          {name: 'GameGuilds',logolinklist:[
            {image: "GameGuilds1.png",name: "INDIGG",outlink: "https://indi.gg/",},
            {image: "GameGuilds2.png",name: "ABEY",outlink: "https://www.abey.com/",},
            {image: "GameGuilds4.png",name: "PACCLUB",outlink: "https://twitter.com/paclub_akg",},
            {image: "GameGuilds3.png",name: "YIELD GUILD",outlink: "https://www.thesmartguild.com/",},
            {image: "GameGuilds5.png",name: "AVOCADO",outlink: "https://avocadoguild.com/",},
            {image: "GameGuilds6.png",name: "X-RAGAMEVERSE",outlink: "http://x-ragameverse.io/",}
          ]},
          {name: 'EXCHANGES',logolinklist:[
            {image: "EXCHANGES1.png",name: "phemex",outlink: "http://phemex.com/spot/trade/REVOUSDT/",},
            {image: "EXCHANGES2.png",name: "pancakeswap",outlink: "https://pancakeswap.finance/info/token/0xfc279e6ff1fb1c7f5848d31561cab27d34a2856b/",},
            {image: "EXCHANGES3.png",name: "huobi",outlink: "https://www.huobi.co.no/en-us/exchange/revo_usdt/",},
            {image: "EXCHANGES4.png",name: "bitmart",outlink: "http://www.bitmart.com/trade/en?layout=basic&symbol=REVO_USDT/",},
            {image: "EXCHANGES5.png",name: "gate",outlink: "https://www.gate.io/ru/trade/REVOLAND_USDT/",},
            {image: "EXCHANGES6.png",name: "mexc",outlink: "http://www.mexc.com/ru-RU/exchange/REVO_USDT/",},
            {image: "EXCHANGES7.png",name: "exchange",outlink: "http://www.lbank.info/exchange/revoland/usdt/#innovation/",},
            {image: "EXCHANGES8.png",name: "bitget",outlink: "http://www.bitget.com/en/spot/REVOUSDT_SPBL/",},
            {image: "EXCHANGES9.png",name: "xswap",outlink: "https://xswap.com/",},
            {image: "EXCHANGES10.png",name: "xplus",outlink: "http://www.x.plus/tradePro/revo_usdt/",}
          ]},


        ]
      }
    }
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  mounted(){ 
    const that = this
    window.addEventListener('scroll',this.onscroll,false);
  },
  methods: {
    onscroll(){
      let ele = this.$refs['partner']
      let top = Math.abs(ele.getBoundingClientRect().top)
      let height = ele.getBoundingClientRect().height
      this.showfate = top<height
    },
    changeindex(index) {
      this.show = index;
    },
    inveLink(index) {
      this.show = index;
    },
  },
  beforeCreate(){ 
    imgsPreloader(partner)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
<style lang="less" scoped>


.partner {text-align: center; color: #fff; border-top: 1px solid;}
.content {display: flex;justify-content: center;flex-direction: column;align-items: center; grid-row-gap: 30px; margin-bottom: 90px; }
.title{ font-size: 60px; flex-shrink: 0;}
.content .tab_box {display: flex;align-items:center;font-size: 24px; grid-gap: 10px; flex-shrink: 0;}
.tab_box .tab_item {width: 293px;height: 60px;line-height: 58px;border: 1px solid #C9FF00; }
.tab_box .tab_item.active { background: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); color: #050020;}
.tab_box .tab_item:hover { background: rgba(201,255,0,0.2);}

.partner_group {display: flex;justify-content: center;flex-wrap: wrap;align-items: center; min-height: 500px; flex:1;}
.partner_group .partner_item { display: flex;justify-content: center;align-items: center;overflow: hidden;padding: 10px 20px;width: 25%;}
.partner_group .partner_item img {width: 100%;}
.partner_group:nth-child(5) .partner_item{ width: 16%;}


@media screen and (max-width: 1100px) {
  .app .title{ font-size: 40px; }
  .app .partner .content {height: auto;}
  .app .content .tab_box {width: 100%;font-size: 0.16rem;justify-content: space-between; flex-wrap: wrap;}
  .app .tab_box .tab_item {width: 1.6rem;height: 0.36rem;line-height: 0.36rem;flex: unset;}
  .app .partner_group{ height: auto;}
  .app .partner_group .partner_item {width: 100%;padding: 10px 30px;}
  .app .partner_group .partner_item a{display: flex;justify-content: center;align-items: center; }
  .app .partner_group .partner_item a{width: 50%;}
  .app .partner_group:nth-child(2) .partner_item { width: 100%; }
  .app .partner_group:nth-child(4) .partner_item img{ height:1rem; width: auto;padding: 0.08rem}
  .app .tab_box .tab_item:hover { background: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); color: #050020;}
}
</style>
