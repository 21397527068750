<!--
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-04-11 12:54:04
 * @LastEditTime: 2023-02-15 10:41:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
-->
<template>
  <div class="group border-line" ref="group">
    <div id="group"></div>
    <video class="video" autoplay="" muted="" playsinline="" loop="" src="/video/center.mp4"></video>
    <div class="content safe_content" >
      <div class="title text-green text-bold ">{{info.title}}</div>
      <div class="desc">
        <span v-for="(item,index) in info.title_sub" :key="index">
          {{item.title}}
        </span>
      </div>
      <div class="btn">
        <button class="text-bold" @click="link( info.btn.link )">{{ info.btn.title }}</button>
      </div>
      <div class="btn_box border-line">
        <a :class="{'animation-fade':showfate}" :href="item.url" v-for="(item,i) in info.list" :key="i" > 
          <img :src="item.imgUrl" />
        </a>
      </div>
    </div> 
  </div>
</template>
<script>
export default { 
  props:{
    info: {
      type: [Object],
      default: function () {
          return { 
          title:this.$t("group.title"),
          desc:this.$t("group.desc"),
          btn_list:[
            {title:this.$t("group.btn1")},
            {title:this.$t("group.btn2")}
          ],
          list:[
            { title:this.$t("group.list1.title"), content:this.$t("group.list1.desc"),},
            { title:this.$t("group.list2.title"), content:this.$t("group.list2.desc"),},
            { title:this.$t("group.list3.title"), content:this.$t("group.list3.desc"),},
            { title:this.$t("group.list4.title"), content:this.$t("group.list4.desc"),}
          ]
        }
      }
    }
  },
  watch: {
    info: {
      handler(nv, ov) {
      },
    },
  },
  data() {
    return {
      showfate:false,
    };
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  mounted(){ 
    const that = this
    window.addEventListener('scroll',this.onscroll,false);
  },
  methods: {
    onscroll(){
      let ele = this.$refs['group']
      let top = Math.abs(ele.getBoundingClientRect().top)
      let height = ele.offsetTop
      this.showfate = top<height
    },
    link(http){ 
      const role = new RegExp('http');
      if(role.test(http)){ 
        // 外联跳转
        window.location.href = http
      }else{ 
        this.$router.push(http);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
<style lang="less" scoped>
.group {color: #fff;font-size: 18px; position: relative; top: 0; left: 0; overflow: hidden; border-bottom: 1px solid; }
.video{ position: absolute;top: 0; left: 0; z-index: 10; width: 100%;}
.content { display: flex; flex-direction: column; align-items: center; justify-content: center;
  position: relative;top: 0; left: 0;z-index: 20; }
.title {font-size: 60px;line-height: 1;text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);white-space:nowrap;}
.desc { display: flex; grid-column-gap: 20px; margin-top:30px;font-size: 14px; }
.desc>span{  
  border: 1px solid; 
  border-image: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%) 4;
  border: 1 1 1 1;
  background-image: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); /* 线性渐变背景，方向向上 */
  -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
  -webkit-text-fill-color: transparent; /* 文字填充颜色变透明 */   
  padding:0 20px; line-height: 2; color: #C9FF00;
}
.btn_box { padding:60px 0; display: grid; justify-content: center; grid-template-columns: repeat(4,1fr); grid-gap: 15px; width: 100%; border-top: 6px solid;}
.btn_box img{ width: 100%; aspect-ratio: 2/1;}
.btn-a {height: 60px;width: 220px;font-size: 16px;color: #fff;background-color: #8357CC;}
.btn{ margin:40px 0;}
.btn>button { cursor:pointer; width: 360px; height: 80px; background: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); color: #050020; font-size: 28px; font-weight: bold; border-radius: 10px;}
.btn>button:hover{ box-shadow: 0px 0px 20px 1px #C9FF00; }

@media screen and (max-width: 1100px) { 
  .app .content { height: auto; min-height: unset; background: url('/video/center.png') no-repeat center/ auto 100%; }
  .app .video{ display: none;}
  .app .content .title {font-size: 0.4rem;line-height: 1.3; white-space:pre-wrap; text-align: center;}
  .app .content .desc {font-size: 0.1rem;flex-wrap: wrap; grid-gap:10px;justify-content: center;}
  .app .content .desc span{padding: 0 0.1rem; line-height: 2.2; }
  .app .content .btn_box {margin-top: 0.2rem; width: 100%;}
  .app .btn-a {width: 1.38rem;height: 0.36rem;margin: 0 10px;font-size: 0.1rem; border-radius: 10px;}
  .app .btn-a {width: 1.38rem;height: 0.36rem;margin: 0 10px;font-size: 0.1rem; border-radius: 10px;}
grid-template-columns: repeat(4,1fr);
  .btn{ margin:40px 0;}
  .btn>button {  width: 2.8rem;height: 0.6rem;font-size: 0.2rem;}
  .btn_box { grid-template-columns: repeat(2,1fr); grid-gap: 10px;}
  
  .app .group-content {flex-wrap: wrap;justify-content: space-around; margin-top:0.2rem ;}
  .app .group-item .count{display: none; }
  .app .group-item .item-content{ padding:  0;}
  .app .group-content .group-item {width: 1.65rem; border-radius: 0.1rem;margin: 0.1rem 0; border: #C2D4F8 solid 1px; padding: 0.1rem; height: auto;}
  .app .group-content .title { font-size: 0.14rem;padding:0.1rem 0.05rem;flex-shrink: 1; display: flex; align-items:center;}
  .app .group-content .desc { font-size: 0.1rem;padding:0.08rem 0.05rem ;border-top: 1px solid #C2D4F8;height:1rem; line-height:1.5; align-items: flex-start;}
}
</style>
