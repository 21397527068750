/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-21 10:18:39
 * @LastEditTime: 2022-05-26 17:29:05
 * @LastEditors: Please set LastEditors
 */

export const common = [
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/sreen6.png',
]

export const partner=[
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors1.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors2.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors6.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors4.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors5.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors3.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/Investors7.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/GameGuilds1.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/GameGuilds2.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/GameGuilds4.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/GameGuilds3.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/GameGuilds5.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS1.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS2.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS3.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS4.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS5.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS6.png',
    'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/team/PARTNERS7.png'
]



export const assets = {
    "pc":{ 
        "en":[ 
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/hero_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/hero_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_3_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_3_en.png',
        ],
        "zh-cn":[
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/hero_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/hero_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/pet_3_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/pc/skin_3_zh-cn.png',
        ]
    },
    "mobile":{ 
        "en":[
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_3_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_3_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_4_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_1_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_2_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_3_en.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_4_en.png',
        ],
        "zh-cn":[
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/hero_3_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_3_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/pet_4_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_1_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_2_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_3_zh-cn.png',
            'https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/assets/m/skin_4_zh-cn.png',
        ]
    }
}






