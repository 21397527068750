/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-01-28 14:44:03
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-02-06 16:28:15
 * @FilePath: \Workspace\revoland\revoland-extension\src\locals\en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    common: {
        "selectLanguage": "EN",
        "message": "Coming soon!"
    },
    home: {
        title: 'The Leading Web3 Gaming Ecosystem',
        desc: `Revolution Land is a leading Web3 game ecosystem developed by Chain X Game, a well-known 
          game company in the Middle East. It is committed to providing services such as publishing, 
          distribution, promotion and NFT transactions for Web3 games through ecological functions such as 
          Gamefi Center, DID, SDK, and DAO. Revolution Land launched the first Web3-based MOBA 
          competitive game in 2022, which has been welcomed by game users around the world. REVO is the 
          governance token of REVOLUTION LAND`,
        btn_list: [
            { logo: "/images/demoImage/home_logo1.png", title: "Web3 Game Center", desc: "Distribution & Marketplace platform Build the Web3 Steam" },
            { logo: "/images/demoImage/home_logo2.png", title: "REVO DID", desc: "The Unique Decentralized ID System focus on Metaverse & GameFi" },
            { logo: "/images/demoImage/home_logo3.png", title: "REVO SDK", desc: "Consulting, Api & advisory Turn Web2 Games to Web3" },
            { logo: "/images/demoImage/home_logo4.png", title: "REVO DAO", desc: "Staking & Earning Protocol By the Community" }
        ],
    },
    game: {
        title: "REVO Web3 GameCenter",
        title_sub: [{ title: 'NFT MarketPlace' }, { title: 'GameFi LaunchPad(IDO)' }, { title: 'NFT Minting / Mystery Boxes(INO)' }, { title: 'Web3 Guild Center' }],
        btn: { title: 'Enter GameCenter', link: '/center' },
        list: [
            { img: '/images/demoImage/蒙版组 2.png', link: '' }, { img: '/images/demoImage/蒙版组 3.png', link: '' },
            { img: '/images/demoImage/蒙版组 4.png', link: '' }, { img: '/images/demoImage/蒙版组 5.png', link: '' },
            { img: '/images/demoImage/蒙版组 6.png', link: '' }, { img: '/images/demoImage/蒙版组 7.png', link: '' },
            { img: '/images/demoImage/蒙版组 8.png', link: '' }, { img: '/images/demoImage/蒙版组 9.png', link: '' },
        ]
    },
    sdk: {
        icon: '/images/sdk.png',
        title: "REVO SDK",
        sub_title: "REVO SDK is being built to give developers and players the power to embrace the new era of Web3 gaming & Metaverse.",
        desc: "We want a platform that is easy to use, one that generates new revenue streams for our partners, and one that has powerful tools to accelerate their game development. Today we’re presenting a key part of our solution for game developers, the REVO SDK.",
        btn: { title: 'Enter REVO SDK', link: '/sdk' },
    },
    did: {
        icon: '/images/did.png',
        title: "REVO DID",
        sub_title: "Replace your complex wallet address with a human-readable identity for all your web3 game interactions",
        desc: "REVO DID (.revo) is a blockchain-based, build on BNB Chain, decentralized account system that provides a worldwide unique naming system with an .revo suffix that can be used in different scenarios, such as cryptocurrency transfer, domain name resolution, i",
        btn: { title: 'Enter REVO DID', link: 'https://www.revodid.com/' },
    },
    dao: {
        icon: '/images/dao.png',
        title: "REVO DAO",
        sub_title: "Staking & Earning Protocol from the Community Our vision is to revolutionize the way we play, live and earn",
        desc:
            `Open up opportunities to receive rewards like REVO / RDT, limited edition NFT's and the chance to help the REVO Games ecosystem grow.,
          The REVO Ecosystem is supported by communities, just like you, who operate REVO DAO from their home computers. By staki`
        ,
        btn: { title: 'Enter REVO DAO', link: "https://bsc.revodao.io/" },
    },
    drawer: {
        navList: [
            { "name": "Home", "name_en": " Home", "outlink": "", "diyname": "/", "son": [] },
            { "name": "GameCenter", "name_en": " GameCenter", "outlink": "", "diyname": "/center", "son": [] },
            { "name": "R-DID", "name_en": "R-DID", "outlink": "", "diyname": "", "son": [] },
            // https://www.revodid.com/
            { "name": "R-SDK", "name_en": "R-SDK", "outlink": "", "diyname": "/sdk", "son": [] },
            { "name": "R-DAO", "name_en": "R-DAO", "outlink": "https://bsc.revodao.io/", "diyname": "", "son": [] },
            { "name": "MOBA", "name_en": "MOBA", "outlink": "https://game.revoland.com/", "diyname": "", "son": [] }
        ],
        bottom_link1: [
            { image: '/images/svg/F4 (1).svg', link: "https://medium.com/@Revolutionland" },
            { image: '/images/svg/F4 (2).svg', link: "https://discord.gg/GtHAQqHy6y" },
            { image: '/images/svg/F4 (4).svg', link: "https://twitter.com/Revolutionland" },
        ]
    },

    center:{
        title: "Web3 Game Center",
        feature:{
          title:"Feature Games",
          list:[
            {image:"/images/center/d5 (1).png",title:'X World Games',icon:'/images/center/b5 (5).png',count:'4.9',eyes:'8.58k',actions:'10.18k'},
            {image:"/images/center/d5 (2).png",title:'Benji Bananas',icon:'/images/center/b5 (1).png',count:'4.9',eyes:'18.65k',actions:'11.26k'},
            {image:"/images/center/d5 (3).png",title:'MOBOX',icon:'/images/center/b5 (3).png',count:'4.9',eyes:'80.25k',actions:'20.14k'},
            {image:"/images/center/d5 (4).png",title:'Meta Apes',icon:'/images/center/b5 (5).png',count:'4.9',eyes:'10.89k',actions:'5.17k'},
            {image:"/images/center/d5 (5).png",title:'Game of Truth',icon:'/images/center/b5 (4).png',count:'4.9',eyes:'13.74k',actions:'10.11k'},
          ]
        },
        guilds:{
          title:"Top Selected Guilds",
          list:[
            { image:'/images/center/u10 (1).png', title:'AVOCADO DAO',desc:'Australia',
            discord:{title:"discode Munber",value:'69,000+'},
            Scholarship:{title:"Scholarship",value:'11,000+'},
            Recruiting:{title:"Recruiting",value:'Actively Recruiting'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (5).png', title:'YGG SEA',desc:'Australia',
            discord:{title:"discode Munber",value:'44,000+'},
            Scholarship:{title:"Scholarship",value:'10,000+'},
            Recruiting:{title:"Recruiting",value:'Actively Recruiting'},
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (9).png', title:'UNIX GAMING GUILD',desc:'Australia',
            discord:{title:"discode Munber",value:'195,000+'},
            Scholarship:{title:"Scholarship",value:'5,000+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (4).png', title:'ANCIENT8',desc:'Australia',
            discord:{title:"discode Munber",value:'70,000+'},
            Scholarship:{title:"Scholarship",value:'3,500+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (8).png', title:'GUILDFI',desc:'Australia',
            discord:{title:"discode Munber",value:'57,000+'},
            Scholarship:{title:"Scholarship",value:'3,000+'},
            Recruiting:{title:"Recruiting",value:'Actively Recruiting'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (3).png', title:'PLAY IT FORWARD DAO',desc:'Australia',
            discord:{title:"discode Munber",value:'19,000+'},
            Scholarship:{title:"Scholarship",value:'3,000+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (7).png', title:'AVISA GAMES GUILD',desc:'Australia',
            discord:{title:"discode Munber",value:'28,000+'},
            Scholarship:{title:"Scholarship",value:'16,000+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (10).png', title:'HATTEN GAMING GUILD',desc:'Australia',
            discord:{title:"discode Munber",value:'2,300+'},
            Scholarship:{title:"Scholarship",value:'1,500+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (2).png', title:'METAGAMING GUILD',desc:'Australia',
            discord:{title:"discode Munber",value:'28,000+'},
            Scholarship:{title:"Scholarship",value:'1,500+'},
            Recruiting:{title:"Recruiting",value:'Actively Recruiting'}, 
            avater:[
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
            { image:'/images/center/u10 (6).png', title:'BATTLE ARENA GAMES...',desc:'Australia',
            discord:{title:"discode Munber",value:'4,500+'},
            Scholarship:{title:"Scholarship",value:'1,000+'},
            Recruiting:{title:"Recruiting",value:'None'}, 
            avater:[{avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
              {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
            ]},
          ]
        },
        igos:{
          title:"Upcoming IGOS",
          list:[
            { image:'/images/center/K5 (1).png', title:'Cyber Arena Community Pool',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/K5 (3).png', title:'CODYFIGHT IGO',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/K5 (4).png', title:'ENGINES OF FURY',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/K5 (2).png', title:'EVERMOON STAKING POOL',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/K5 (5).png', title:'Monsterra Community',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/p (3).png', title:'XANA Community',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/p (2).png', title:'X-Metaverse Community',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/p (4).png', title:'MetaSpets Community',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/p (1).png', title:'Drunk Robots Community',desc:'BUYING PHASE STARTS IN'},
            { image:'/images/center/p (5).png', title:'Rebel Bots Community',desc:'BUYING PHASE STARTS IN'},
           
          ]
        }
    },
    



    SDK: {
        title: "REVO SDK",
        sub_title:`Everything possible with blockchain technologies, such as new gameplay
            mechanics, virtual asset trading, user-generated content monetization, new
            marketing strategies, and even in-game financial incentivisation mechanics are
            soon available to all developers with unprecedented ease.`,
        desc:`We enable game developers to focus solely on what they love and do best which is
            making games with awesome features. Chain X Game does this by handling what we
            do best: taking care of all the complicated blockchain related features such as:`,
        list:[
            `Transparent, blockchain account creation`,
            `Private keys security and blockchain account recovery`,
            `Access to blockchain data.`,
            `Management of a user’s blockchain NFT inventory.`,
            `Facilitating game clients to make transactions on the blockchain without any middleman, allowing users to interact directly with the blockchain.`,
        ],
        btn: {name: "CONNECT SDK",link: ""},
        overview:{ 
            title:"SDK Overview",
            list:[
            {title:"Blockchain Support",desc:["Ethereum","BNB Chain","Polygon","Solana","ABEY Chain"]},
            {title:"NFT Toolbox",desc:["InGame MarketPlace","Mystery Box","NFT Minting","NFT Staking","NFT Lending"]},
            {title:"Web3 User System",desc:["Web3 Account Creation","Web3 Referral System"]},
            {title:"Integrated Wallet",desc:["Qmnichain Payment","Cross Chain sWAP","Wallet Functions"]},
            {title:"Marketing Tools",desc:["Web3 Guild System"]},
            ],
        },
        features: {
            title:"SDK Overview",
            list:[
            {title: "All-in-one Login & Authentication",desc: "Using APIs and SDKs, game developers can harness the power of blockchain technology without any complex web3 code.",},
            {title: "Mobile In-App Wallet Integration",desc:`Integrate with all types of payment methods easily. Allow NFT assets purchase with Fiat currencies and mainstream cryptocurrencies in your own marketplace. `,},
            {title: "Easily Build Mobile NFT Market",desc:"Easy deployment of Mobile and Web market SDK. Build your own mobile NFT market with existing templates with the best indexing experience. (Easily approved by Apple Store)",},
            {title: "Zero-Code Launch Your NFT",desc:"A developer-friendly creator platform that helps you launch, mint and trade NFT without writing code.",},
            {title: "Multi-Chain Support",desc:"We support the most popular EVM blockchains (Ethereum, BNB Chain, Polygon) and Polygon, posing no limitations to developers. ",},
            {title: "Pre-Build Smart Contract",desc: "In addition to the most basic functions like listing and trading, you can expect the following features: IGO for LaunchPad / SelfPublishing Mystery Box contract / NFT Lending",},
            {title: "Walletverse Integration",desc: "Build with a powerful blockchain built-in wallet in just 30 minutes with the walletverse SDK and seamlessly embed Web2 login, NFT, payments, DAO and other applications. ",},
            {title: "ABEY Chain & Community",desc: "With up to 3,000 TPS and Low Gas Fees ABEYCHAIN is fast becoming the choice of developers. A Multi-Layered Programmable Blockchain for High-Volume Transactions.",},
            ]
        },
    },
    footer:{ 
        statement:{ 
          'service':{ title:'Terms of Service'},
          'policy':{title:'Privacy Policy'},
          'contact':{title:'Contact Us'},
          'rights':{title:'© 2023 Revolution Land All rights reserved'},
        }
      }
}