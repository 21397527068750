<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-15 10:45:51
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \revlandPresalee:\demo\revoland\src\components\footer.vue
-->
<template>
  <div class="footer border-line">
    <div class="content ">
      <div class="logo_box">
        <img class="logo" :src="info.footLogo" @click="goAnchor">
        <div>
          <div class="item">  
            <a :href="item.link" v-for="(item, idx) in info.words" :key="idx">{{item.title}}</a>
          </div>
          <p>{{info.copyright}}</p>
        </div>
      </div>
      <div class="icon_box">
        <a class="icon_item" :href="item.url" v-for="(item, idx) in info.bottom_link1" :key="idx">
          <img :src="item.imgUrl" alt />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  created(){
    this.$watch('$store.state.block', (newVal) => {
      this.info = newVal.drawer
    },{immediate:true})
  },
  data() {
    return { 
      info:{
        footer:{},
        bottom_link1:[]
      },
    };
  },
  methods: {
    // 默认页面
    goAnchor(selector='home') {
      var anchor= document.querySelector('#'+selector)
      if(!anchor){ 
        this.$router.push({ path: '/' });
        return false
      }
      document.documentElement.scrollTop = anchor.offsetTop
    }
  },
};
</script>
<style lang="less" scoped>
.footer {color: #C2D4F8; border-top: 1px solid; }
.footer .content { display: flex;justify-content: space-between; height: 200px; padding: 0 30px; line-height: 2;min-height:unset;}

.logo_box{ align-self: center;display: flex; align-items: center; grid-column-gap: 20px; width: 100%;}

.logo_box .logo{ width: 100px; height: 100px;}
.logo_box .item{display: flex;grid-column-gap: 10px; }

.link_box{ display: flex;justify-content: flex-end;flex: 1; }
.link_box .link_item{ margin-left: 120px;}
.link_box .link_item a {color: #C2D4F8;}
.icon_box{ display: flex;  align-items: center;  }
// .icon_box .icon_item{ color: #fff; font-size: 20px; margin: 10px; line-height: 1;text-decoration: none;}
.icon_box .icon_item{ width: 30px; height: 30px;  line-height: 30px; margin-left:20px ;}
.icon_box .icon_item img{ width: 100%; height: auto;}
.copyright_box{display: flex; justify-content: space-between; width: 100%; border-top:#C2D4F8 solid 1px; line-height: 4;}
.copyright_box_app{ display: none;}

@media screen and (max-width: 1100px) {
  .app .footer {font-size: 0.12rem; }
  .app .logo_box{ grid-column-gap: 10px;}
  .app .logo_box>div{ flex:1 }
  .app .logo_box .item{display: flex;grid-column-gap: 5px; justify-content: space-between; margin-bottom: 5px; text-decoration: underline;}
  .app .panel_box{display: none;}
  .app .footer .content {height: 1.5rem;line-height: 1.5;padding: 0.2rem 0.2rem;flex-direction: column; justify-content: space-around; align-items: flex-start; flex-wrap: nowrap;}
  .app .logo_box .logo{ width: 50px; height: 50px;}
  .app .icon_box .icon_item{ margin: 0 0.08rem; width: 0.24rem; height: 0.4rem; line-height: 0.4rem; font-size: 0.2rem;}
}
</style>