/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-04-11 10:43:17
 * @LastEditTime: 2023-02-15 10:43:23
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import { Dropdown, DropdownMenu, DropdownItem, Drawer, Notification, Message, Carousel, CarouselItem,Menu,MenuItem,Row,Col ,Card,Tooltip} from "element-ui";
import { imgsPreloader } from './utils/imgPreloader/imgPreloader.js';

import "./assets/less/element-variables.scss";
import "element-ui/lib/theme-chalk/display.css";

import { i18n } from "./locals";
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import env from "./config/env.js";
import axios from "axios";
import qs from "qs";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";


Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Drawer);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Tooltip);

Vue.use(VueLazyload, {
  preLoad: 1.5,
  error: '../src/assets/image/error.png',
  loading: '../src/assets/image/loading.gif',
  attempt: 1
})
i18n.locale = store.getters.getLang;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$ajax = axios;
Vue.prototype.$qs = qs;
Vue.config.productionTip = false;
Vue.prototype.$env = env;

export default (async () => {
  //预加载
  // let time = window.setTimeout(function(){ 
 
  //   window,clearTimeout(time)
  // },1000)
  await imgsPreloader();
  // await axios.post(env.BASEURL + "/addons/cms/api/block", {
  //     apikey: "ladfdddfdfd",
  //     lang:store.state.lang,
  //   }
  // )
  // .then((res) => {
  //   const result = res.data.data;
  //   let partner = result.logolink.channel
  //   const data = i18n.messages[store.state.lang];
  //   store.state.block = { ...data, partner }
  // }); 
  await axios.post(
    env.BASEURL+"?s=/api/home/dicts", 
    { apikey: "ladfdddfdfd",lang:store.state.lang =="zh-cn"?"zh-cn":"en-us"}
  ).then((res) => {
      const result = res.data.data;
      const home={
        title:result.home_title,
        desc:result.home_detail,
        btn_list:result.home_btn_list
      };
      const game={ 
        title:result.game_title,
        title_sub:result.game_title_list,
        btn:{ title:result.game_btn,link:result.game_btn_link},
        list:result.game_list
      };
      const sdk={
        icon:result.sdk_logo,
        title:result.sdk_title,
        sub_title:result.sdk_title2,
        desc:result.sdk_detail,
        btn:{ title:result.sdk_btn ,link:result.sdk_btn_link},
      };
      const did={
        icon:result.did_logo,
        title:result.did_title,
        sub_title:result.did_title2,
        desc:result.did_detail,
        btn:{ title:result.did_btn,link:result.did_btn_link},
      };
      const dao = {
        icon:result.dao_logo,
        title:result.dao_title,
        sub_title:result.dao_title2,
        desc:result.dao_detail,
        btn:{ title:result.dao_btn,link:result.dao_btn_link},
      };
      // gameCenter
      const partner=[
        {name: 'INVESTORS',logolinklist:result.investors_list},
        {name: 'PARTNERS',logolinklist:result.partners_list},
        {name: 'GameGuilds',logolinklist:result.guilds_list},
        {name: 'EXCHANGES',logolinklist:result.exchanges_list}
      ]

      const center = {
        title: result.center_game_title,
        feature:{title:result.center_game_title2,list:result.feature_list},
        guilds:{title:result.center_guilds_title,list:result.center_guilds_list},
        igos:{title:result.center_igo_title,list:result.igo_list}
      };

      const SDK = {
        title: result.rsdk_title,
        sub_title:result.rsdk_title2,
        desc:result.rsdk_desc,
        list:result.rsdk_use_list,
        btn:{name: result.rsdk_btn,link:result.rsdk_btn_link},
        overview:{ 
          title:result.rsdk_overview_one,
          list:result.rsdk_overview_one_list
        },
        features:{ 
          title:result.rsdk_overview_two,
          list:result.rsdk_overview_two_list
        }
      };
      const drawer = { 
        navList:result.nav_list, 
        bottom_link1: result.foot_icon_list,
        words:result.foot_words,
        copyright:result.copyright,
        footLogo:result.foot_logo,
        navLogo:result.nav_logo
      }

      // console.log( result )
      store.state.block = { home,game,sdk,did,dao,center,SDK,partner,drawer }
  })
  document.getElementById('loader-wrapper').style.display="none"
  //let images =  console.log(images)
  //关闭加载弹框
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
})()






