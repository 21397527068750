/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-21 10:18:14
 * @LastEditTime: 2022-05-26 16:29:40
 * @LastEditors: Please set LastEditors
 */

import {common} from "./config.js";
// export 
const imgPreloader = url => {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (err) => {
            reject(false);
        };
        image.src = url;
    });
};

/**
 * @description: 预加载图片-让浏览器有缓存, 显示不再请求
 * @return {*}
 * @param {array}
 */
export const imgsPreloader = (imgs = common) => {
    let promiseArr = [];
    // 遍历模块
    imgs.forEach(element => {
        const imgitem = imgPreloader(element).catch(err=>{})
        if (imgitem){ 
            promiseArr.push(imgitem);
        }
    });
    return Promise.all(promiseArr);
};
