<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-14 15:44:48
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="home">
    <Home :info='block.home' />
    <!-- REVO Web3 GameCenter -->
    <GameCenter :info="block.game"/>
    <!-- REVO SDK -->
    <div class="wrapper">
      <!-- REVO SDK -->
      <Developer :info='block.sdk'/>
      <!-- REVO DID -->
      <Developer :info='block.did'/>
      <!-- REVO DAO -->
      <Developer :isDao="true" :info='block.dao'/>
    </div>
    <!-- Partnerships -->
    <Partner :info="block.partner"/>
    <Footer/>
  </div>
</template>
<script>
import Home from "@/components/section/home"; //首页 
import Partner from "@/components/section/partner"; //合作方
import Developer from "@/components/section/developer"; //开发商 
import GameCenter from "@/components/section/GameCenter"; //开发商 
import Footer from "@/components/footer"//页脚

export default {
  components: {
    Home,
    GameCenter,
    Developer,
    Partner,
    Footer,
  },
  data() {
    return {
      block:{},
    };
  },
  created() {
    this.$watch('$store.state.block', (newVal, oldVal) => {
      this.block = newVal
    },{immediate:true,deep:true})
  },
 
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  mounted() {
    if(!this.isMobile) { 
      // window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚动条滚动事件
    }
    // document.addEventListener("mousewheel", this.handleWheel ); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    handleScroll(e) {
      let elementList = ['home','faq','assets','earn','group','developer','partner','news']
      elementList.forEach(id => {
        const BoundingClientRect = document.getElementById(id).getBoundingClientRect();
        if( Math.abs(BoundingClientRect.top) < 50 ){
          this.goAnchor('#'+id)
        }
      });
    },
    handleWheel(e){ 
      let windowHeight = document.documentElement.clientHeight; //屏幕高度
      if (e.wheelDelta || e.detail) {
        if (e.wheelDelta > 0 || e.detail < 0) {     //当鼠标滚轮向上滚动时
          document.documentElement.scrollTop -= windowHeight
        }
        if (e.wheelDelta < 0 || e.detail > 0) {     //当鼠标滚轮向下滚动时
          document.documentElement.scrollTop += windowHeight
        }
      }
    },
    goAnchor(selector) {
      var anchor = document.querySelector(selector)
      document.documentElement.scrollTop = anchor.offsetTop
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousewheel", this.handleWheel);
  },
};
</script>
<style lang="less" scoped>
/deep/.developer:nth-child(1){ 
   background: linear-gradient(90deg,  rgba(2,2,2,0.5) 15%, rgba(34,39,59,0.5) 51%,  rgba(2,2,2,0.5) 85%);
}
/deep/.developer:nth-child(2){ 
   background: linear-gradient(90deg, rgba(10,10,10,0.5) 15%, rgba(34,39,59,0.5) 51%, rgba(10,10,10,0.5) 85%);
}
/deep/.developer:nth-child(3){ 
   background: linear-gradient(90deg, rgba(19,19,19,0.6) 15%, rgba(34,39,59,0.6) 51%, rgba(19,19,19,0.6) 85%);
}
.wrapper{ position: relative;top: 0; left: 0;}
</style>




 