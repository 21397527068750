<!--
    * @Author: your name
    * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-14 14:33:41
 * @LastEditors: 叹息桥丶 648161532@qq.com
    * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
    * @FilePath: \vue-portale:\demo\revoland\src\view\center.vue
   -->
<template>
  <div class="sdk">
    <div class="wrapper">
      <video class="video" autoplay="" muted="" playsinline="" loop="" src="/video/sdk.mp4"></video>
      <section class="content">
        <p class="title text-linear text-bold text-green">REVO SDK</p>
        <p class="desc"></p>
        <div class="btn">
          <button class="text-bold" @click="link(info.btn.link)">{{ info.btn.name }}</button>
        </div>
      </section>
    </div>

    <div class="sdk_main">
      <section class="sub_content safe_content">
        <p class="text-bold">{{info.sub_title}}</p>
        <p>{{info.desc}}</p>
        <ul>
          <li class="text-green" v-for="(item,index) in info.list" :key="index">{{item.content}}</li>
        </ul>
      </section> 
      <section class="sub_content">
        <div class="title text-green text-bold">{{ info.overview.title }}</div>
        <div class="overview safe_content">
          <div class="content" v-for="(items, i) in info.overview.list" :key="i">
            <p class="title">{{ items.title }}</p>
            <p class="desc">
              <span class="text-green" v-for="(item, index) in items.b_title.split(',')" :key="i+'-'+index">{{ item }}</span>
            </p>
          </div>
        </div>
      </section>
    
      <section class="sub_content">
        <div class="title text-green text-bold">{{info.features.title}}</div>
        <div class="features safe_content">
          <div class="content" v-for="(item, i) in info.features.list" :key="i">
            <p class="title text-green text-bold">{{ item.title }}</p>
            <p class="desc">{{ item.content }}</p>
          </div>
        </div>
      </section>
    </div> 

    <!-- <Footer /> -->
  </div>
</template>
<script>
// import Footer from "@/components/footer"; //页脚

export default {
  components: {
    // Footer,
  },
  created() {
    this.$watch('$store.state.block', (newVal, oldVal) => {
      this.info = newVal.SDK
      console.log(newVal.SDK)
    },{immediate:true,deep:true})
  },
  data() {
    return {
      info: {},
    };
  },

  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  mounted() {
    if (!this.isMobile) {
      // window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚动条滚动事件
    }
    // document.addEventListener("mousewheel", this.handleWheel ); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    link() {
      console.log("跳转");
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousewheel", this.handleWheel);
  },
};
</script>
<style lang="less" scoped>

.sdk{}
.safe_content{ height: auto; min-height: 500px; padding: 60px 0 90px;}

/* 第一块 */
.wrapper{ position: relative; top: 0; left: 0; overflow: hidden;}
.wrapper video{ position: absolute;top: -60%; left: 0; z-index: 10; width: 100%; height: auto; }
.wrapper .content{ height: 450px; padding-top: 80px; z-index: 20; position: relative; background: linear-gradient(180deg, rgba(19,19,19,0) 80%, #050505 100%); display: flex; flex-direction: column; justify-content: center; align-items: center;}
.wrapper .content>.title{ font-size: 64px; }
.btn{ padding: 30px 0; }
.btn>button { cursor:pointer; width: 360px; height: 80px; background: linear-gradient(90deg, #FEF71D 0%, #C9FF00 100%); color: #050020; font-size: 28px; }
.btn>button:hover{ box-shadow: 0px 0px 20px 1px #C9FF00; }
.sdk_main{background: linear-gradient(180deg, #050505 0%, #1B1D27 51%, #131313 100%); }
.sdk_main .sub_content{ display: flex; flex-direction: column; grid-row-gap: 30px; font-size: 18px; }
.sub_content ul{ list-style: disc; padding-left: 20px; }

/* SDK Overview */
.sub_content>.title{ font-size: 60px; text-align: center;border-bottom: 2px solid; border-image:linear-gradient(270deg, #131313 0%, #C9FF00 51%, #131313 100%) 4; border-width: 0 0 0 100;}
.overview{ display: flex; grid-row-gap: 30px; flex-direction: column; font-size: 18px; }
.overview .content{ display: grid; grid-template-columns: 200px auto; grid-row-gap: 50px; }
.overview .title{ }
.overview .desc{ display: flex; grid-column-gap: 10px; }
.overview .desc>span{ border: 1px solid #C9FF00; font-size: 18px; line-height: 1.8; padding: 0 20px; }


/* SDK Features */
.features{ display: grid; grid-template-columns: repeat(2,1fr); grid-gap: 30px; justify-items: center;  }
.features>div{ width: 70%; }

@media screen and (max-width: 1100px) {
  .wrapper .content{ height: 3rem;}
 .app .wrapper .video{ display: none; }
 .app .wrapper { background: url('/video/sdk.png') no-repeat center/100% auto; text-align: center;}
 .app .wrapper .content>.title{ font-size: 0.4rem;}
  .app .btn{ padding: 20px 0; }
  .app .btn>button { width: 2.8rem; height: 0.6rem;  font-size: 0.2rem; }
  
  .app .safe_content{ padding: 0.3rem 0.2rem 0.5rem;}
  .app .sub_content > .title{ font-size: 0.32rem;}
  .app .sub_content > .content{ padding: 0.3rem  0.2rem  0.6rem;}
  .app .features{ grid-template-columns: repeat(1,1fr); grid-gap:0.2rem;}
  .app .features > div{width: 100%;}
  .app .overview .content{ grid-template-columns: repeat(1,1fr); font-size: 0.24rem; text-align: center; grid-row-gap: 0.2rem;}
  .app .overview .desc{ flex-wrap: wrap; grid-gap:0.1rem; justify-content: center;}
 
  // .app .sub_content{ display: flex; flex-direction: column; grid-row-gap: 30px; font-size: 14px; }

}
</style>
