<!--
 * @Author: your name
 * @Date: 2022-04-11 10:38:20
 * @LastEditTime: 2023-02-15 10:44:31
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \revlandPresalef:\workSpace\website\src\App.vue
-->
<template>
  <div id="app" :class="[!isMobile ? 'pc' : 'app']">
    <Header/>
    <router-view id="main" />
    <Drawer />
  </div>
</template>
<script>
 
import Header from "@/components/header";
import Drawer from "@/components/drawer";//侧边栏

export default {
  name: "App",
  components: {
    Header,
    Drawer
  },
  mounted() {
    console.log('加载App')
  },
  created() {
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      console.log('语言包更新，当前语言：',newVal,' 更新数据')
      // const data = this._i18n.messages[newVal];
      // this.$store.state.block = { ...this.$store.state.block,...data}
      this.getInfo()
    })
  },
  methods:{
    getInfo(){ 
      this.$ajax.post(
        this.$env.BASEURL+"?s=/api/home/dicts", 
        { apikey: "ladfdddfdfd",lang:this.$store.state.lang =="zh-cn"?"zh-cn":"en-us"}
      ).then((res) => {
          const result = res.data.data;
          const home={
            title:result.home_title,
            desc:result.home_detail,
            btn_list:result.home_btn_list
          };
          const game={ 
            title:result.game_title,
            title_sub:result.game_title_list,
            btn:{ title:result.game_btn,link:result.game_btn_link},
            list:result.game_list
          };
          const sdk={
            icon:result.sdk_logo,
            title:result.sdk_title,
            sub_title:result.sdk_title2,
            desc:result.sdk_detail,
            btn:{ title:result.sdk_btn ,link:result.sdk_btn_link},
          };
          const did={
            icon:result.did_logo,
            title:result.did_title,
            sub_title:result.did_title2,
            desc:result.did_detail,
            btn:{ title:result.did_btn,link:result.did_btn_link},
          };
          const dao = {
            icon:result.dao_logo,
            title:result.dao_title,
            sub_title:result.dao_title2,
            desc:result.dao_detail,
            btn:{ title:result.dao_btn,link:result.dao_btn_link},
          };
          // gameCenter
          const partner=[
            {name: 'INVESTORS',logolinklist:result.investors_list},
            {name: 'PARTNERS',logolinklist:result.partners_list},
            {name: 'GameGuilds',logolinklist:result.guilds_list},
            {name: 'EXCHANGES',logolinklist:result.exchanges_list}
          ]
          const center = {
            title: result.center_game_title,
            feature:{title:result.center_game_title2,list:result.feature_list},
            guilds:{title:result.center_guilds_title,list:result.center_guilds_list},
            igos:{title:result.center_igo_title,list:result.igo_list}
          };
          const SDK = {
            title: result.rsdk_title,
            sub_title:result.rsdk_title2,
            desc:result.rsdk_desc,
            list:result.rsdk_use_list,
            btn:{name: result.rsdk_btn,link:result.rsdk_btn_link},
            overview:{ 
              title:result.rsdk_overview_one,
              list:result.rsdk_overview_one_list
            },
            features:{ 
              title:result.rsdk_overview_two,
              list:result.rsdk_overview_two_list
            }
          };
          const drawer = { 
            navList:result.nav_list, 
            bottom_link1: result.foot_icon_list,
            words:result.foot_words,
            copyright:result.copyright,
            footLogo:result.foot_logo,
            navLogo:result.nav_logo
          }
          this.$store.state.block = { home,game,sdk,did,dao,center,SDK,partner,drawer }
      })
    }
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
}
</script>

<style lang="less">
@import "assets/less/index.css";
@import "assets/less/animation.css";
@import "assets/less/animate.css";
@import "assets/less/font.css";
</style>

