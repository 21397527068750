<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-02-14 16:38:08
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="home">
    <div id="home"></div>
    <video class="video" autoplay="" muted="" playsinline="" loop="" src="/video/home.mp4"></video>
    <div class="wrapper"  ref="home">
      <div class="content safe_content"  >
       <div class="content_box">
        <div class="title shadow-text text-green text-bold">{{info.title}}</div>
          <div class="title_sub shadow-text">
            {{info.desc}}
          </div>  
          <div class="btn_box">
            <div class="btn_item " v-for="(item,index) in info.btn_list" :key="index" > 
              <img :src="item.icon" :class="{'animation-scale-up':showfate}"/>
              <div class="btn_title text-green">{{ item.title }}</div>
              <div class="btn_desc">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showfate:true
    };
  },
  props:{
    info: {}
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  mounted(){
    const that = this
    window.addEventListener('scroll',this.onscroll,false);
  },
  methods: {
    // showNotify() {
    //   this.$message({
    //     message: this.$t("common.message"),
    //     center: true,
    //     duration: 1000,
    //   });
    // },
    onscroll(){
      let ele = this.$refs['home']
      let top = Math.abs(ele.getBoundingClientRect().top) 
      let height = ele.getBoundingClientRect().height
      this.showfate = top<height
    },
    link(http){ 
      const role = new RegExp('http');
      if(role.test(http)){ 
        // 外联跳转
        window.location.href = http
      }else{ 
        this.$router.push(http);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
<style lang="less" scoped>
@keyframes lamp {
  0% {opacity: 1;transform: translateX(0%);}
  100% {opacity: 1;transform: translateX(50%);}
}
.gradient-text{  
  background-image:-webkit-linear-gradient(left,rgba(146, 82, 234, 1),rgba(64, 216, 221, 1)); 
  -webkit-background-clip:text; 
  -webkit-text-fill-color:transparent; 
}
.safe_content{ max-width: 1680px;}
.shadow-text{  
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 1);
}
.home{ position: relative; top: 0; left: 0; overflow: hidden;}
.video{ position: absolute;top: 0; left: 0; z-index: 10; width: 100%;}
.wrapper{z-index: 20; position: relative; box-shadow:inset 0px -100px 50px 0px rgba(0,0,0,1) ;}

// .home {background: url(https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/sreen6.png) no-repeat; background-position: 50% 50%;}
.home .content {display: flex; align-items: center; justify-content: center;}

.content .content_box{ display: flex;flex-direction: column;align-items: center;justify-content: space-around;  width: 100%;text-align: center;margin-top: -60px}
.content_box .title {font-size: 60px;line-height: 1.5;margin-bottom:30px ;}
.content_box .title_sub {font-size: 18px;line-height: 1.5;width: 65%;margin-bottom:60px;}



.btn_box { width: 100%; display: grid; 
  grid-template-columns: repeat(4,auto); 
  -webkit-grid-template-columns: repeat(4,auto); 
  grid-gap: 50px; overflow: hidden; }
.btn_item { border-radius:20px; padding: 20px  0; backdrop-filter: blur(30px);word-break: break-all;}
.btn_item::before{ backdrop-filter: blur(37px); }

.btn_item>img{ height: 170px; width: 170px; }
.btn_item .btn_title{ font-size: 24px; line-height: 1.5; }
.btn_item .btn_desc{ font-size: 18px; line-height: 1.5; }

.lamp{font-size: 40px;  margin-bottom: -1px; animation: lamp 2s infinite; display: inline-block;}

@media screen and (max-width: 1100px) {
  .home{ background: url(/video/home.png) no-repeat center/ auto 100%;}
  .video{ display: none; }
  .app .content .content_box{ margin-top: 20px;}
  .app .content_box .title {font-size: 0.30rem;  line-height: 1.3;}
  .app .content_box .title_sub {font-size: 0.14rem; line-height: 1.5;width: 100%;}
  .app .content_box .desc{font-size: 0.1rem; width: 100%; }
  .app .btn_box{ grid-template-columns: repeat(2,1fr); grid-gap: 10px; }
  .app .btn_item {width:1.6rem;font-size: 0.1rem;  background-color: rgba(19,19,19, 0.8); backdrop-filter: unset;}
  .btn_item>img{ height: 1.4rem; width: 1.4rem; }
  .btn_item .btn_title{ font-size: 0.16rem; line-height: 1.5; }
  .btn_item .btn_desc{ font-size: 0.12rem; line-height: 1.5; }
  .lamp{font-size: 0.15rem;}
}
</style>
