

export default {
  common: {
    "selectLanguage": "繁體中文",
    "message": "敬請期待！"
  },
  home:{
    title:'领先的Web3游戏生态系统',
    desc:`Revolution Land是由中东知名游戏公司Chain X Game开发的领先的Web3游戏生态系统。 致力于通过Gamefi Center、DID、SDK、DAO等生态功能，为Web3游戏提供发布、发行、推广、NFT交易等服务。 Revolution Land在2022年推出了第一款基于Web3的MOBA竞技类游戏，受到了全球游戏用户的欢迎。 REVO 
    是REVOLUTION LAND的治理代币`,
    btn_list:[
      {logo:"/images/demoImage/home_logo1.png",title:"Web3 Game Center",desc:"Distribution & Marketplace platform Build the Web3 Steam"},
      {logo:"/images/demoImage/home_logo2.png",title:"REVO DID",desc:"The Unique Decentralized ID System focus on Metaverse & GameFi"},
      {logo:"/images/demoImage/home_logo3.png",title:"REVO SDK",desc:"Consulting, Api & advisory Turn Web2 Games to Web3"},
      {logo:"/images/demoImage/home_logo4.png",title:"REVO DAO",desc:"Staking & Earning Protocol By the 社区"}
    ],
  },
  game:{ 
    title:"REVO Web3 游戏中心",
    title_sub:[{title:'NFT市场'},{title:'GameFi LaunchPad(IDO)'},{title:'NFT挖矿 / 盲盒(INO)'},{title:'Web3工会中心'}],
    btn:{ title:'进入游戏中心',link:'/中心'},
    list:[
      { img:'/images/demoImage/蒙版组 2.png',link:''}, { img:'/images/demoImage/蒙版组 3.png',link:''},
      { img:'/images/demoImage/蒙版组 4.png',link:''}, { img:'/images/demoImage/蒙版组 5.png',link:''},
      { img:'/images/demoImage/蒙版组 6.png',link:''}, { img:'/images/demoImage/蒙版组 7.png',link:''},
      { img:'/images/demoImage/蒙版组 8.png',link:''}, { img:'/images/demoImage/蒙版组 9.png',link:''},
    ]
  },
  sdk:{
    icon:'/images/sdk.png',
    title:"REVO SDK",
    sub_title:"REVO SDK的构建旨在为开发者和玩家提供拥抱Web3游戏和元宇宙新时代的力量。",
    desc:"我们想要一个易于使用的平台，一个可以为我们的合作伙伴创造新收入来源的平台，一个拥有加速他们游戏开发的强大工具的平台。今天我们为游戏开发者展示了我们解决方案的一个关键部分：REVO SDK",
    btn:{ title:'进入REVO SDK',link:'/sdk'},
  },
  did:{
    icon:'/images/did.png',
    title:"REVO DID",
    sub_title:"将您复杂的钱包地址替换为您所有 web3 游戏交互的人类可读身份",
    desc:"REVO DID (.revo) 是一个基于区块链的、建立在BNB Chain上的去中心化账户系统，提供全球唯一的命名系统，后缀为.revo，可用于不同场景，如加密货币转账、域名解析等",
    btn:{ title:'进入REVO DID',link:'https://www.revodid.com/'},
  },
  dao:{
    icon:'/images/dao.png',
    title:"REVO DAO",
    sub_title:"来自于社区的质押赚钱协议 我们的愿景是彻底改变我们玩游戏、生活和赚钱的方式",
    desc:
      `提供获得 REVO / RDT、限量版 NFT 等奖励的机会，以及帮助 REVO Games 生态系统发展的机会。 REVO生态系统得到社区的支持，社区成员像您一样，他们在家里的计算机上操作 REVO DAO`
    ,
    btn:{ title:'进入 REVO DAO',link:"https://bsc.revodao.io/"},
  },
  drawer:{ 
    navList:[
      {"name":"Home","name_en":" Home","outlink":"","diyname":"/","son":[]},
      {"name":"GameCenter","name_en":" GameCenter","outlink":"","diyname":"/center","son":[]},
      {"name":"R-DID","name_en":"R-DID","outlink":"","diyname":"","son":[]},
      // https://www.revodid.com/
      {"name":"R-SDK","name_en":"R-SDK","outlink":"","diyname":"/sdk","son":[]},
      {"name":"R-DAO","name_en":"R-DAO","outlink":"https://bsc.revodao.io/","diyname":"","son":[]},
      {"name":"MOBA","name_en":"MOBA","outlink":"https://www.revoland.com/","diyname":"","son":[]}
    ], 
    bottom_link1:[ 
      { image:'/images/svg/F4 (1).svg',link:"https://medium.com/@Revolutionland"},
      { image:'/images/svg/F4 (2).svg',link:"https://discord.gg/GtHAQqHy6y"},
      { image:'/images/svg/F4 (4).svg',link:"https://twitter.com/Revolutionland"},
    ]
  },
  center: {
    title: "Web3游戏中心",
    feature:{
      title:"特色游戏",
      list:[
        {image:"/images/center/d5 (1).png",title:'X World Games',icon:'/images/center/b5 (5).png',count:'4.9',eyes:'8.58k',actions:'10.18k'},
        {image:"/images/center/d5 (2).png",title:'Benji Bananas',icon:'/images/center/b5 (1).png',count:'4.9',eyes:'18.65k',actions:'11.26k'},
        {image:"/images/center/d5 (3).png",title:'MOBOX',icon:'/images/center/b5 (3).png',count:'4.9',eyes:'80.25k',actions:'20.14k'},
        {image:"/images/center/d5 (4).png",title:'Meta Apes',icon:'/images/center/b5 (5).png',count:'4.9',eyes:'10.89k',actions:'5.17k'},
        {image:"/images/center/d5 (5).png",title:'Game of Truth',icon:'/images/center/b5 (4).png',count:'4.9',eyes:'13.74k',actions:'10.11k'},
      ]
    },
    guilds:{
      title:"精选公会",
      list:[
        { image:'/images/center/u10 (1).png', title:'AVOCADO DAO',desc:'Australia',
        discord:{title:"discode 用户",value:'69,000+'},
        Scholarship:{title:"奖学金",value:'11,000+'},
        Recruiting:{title:"人员招募",value:'积极招募'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (5).png', title:'YGG SEA',desc:'Australia',
        discord:{title:"discode 用户",value:'44,000+'},
        Scholarship:{title:"奖学金",value:'10,000+'},
        Recruiting:{title:"人员招募",value:'积极招募'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (9).png', title:'UNIX GAMING GUILD',desc:'Australia',
        discord:{title:"discode 用户",value:'195,000+'},
        Scholarship:{title:"奖学金",value:'5,000+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (4).png', title:'ANCIENT8',desc:'Australia',
        discord:{title:"discode 用户",value:'70,000+'},
        Scholarship:{title:"奖学金",value:'3,500+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (8).png', title:'GUILDFI',desc:'Australia',
        discord:{title:"discode 用户",value:'57,000+'},
        Scholarship:{title:"奖学金",value:'3,000+'},
        Recruiting:{title:"人员招募",value:'积极招募'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (3).png', title:'PLAY IT FORWARD DAO',desc:'Australia',
        discord:{title:"discode 用户",value:'19,000+'},
        Scholarship:{title:"奖学金",value:'3,000+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (7).png', title:'AVISA GAMES GUILD',desc:'Australia',
        discord:{title:"discode 用户",value:'28,000+'},
        Scholarship:{title:"奖学金",value:'16,000+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (10).png', title:'HATTEN GAMING GUILD',desc:'Australia',
        discord:{title:"discode 用户",value:'2,300+'},
        Scholarship:{title:"奖学金",value:'1,500+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (2).png', title:'METAGAMING GUILD',desc:'Australia',
        discord:{title:"discode 用户",value:'28,000+'},
        Scholarship:{title:"奖学金",value:'1,500+'},
        Recruiting:{title:"人员招募",value:'积极招募'}, avater:[
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
        { image:'/images/center/u10 (6).png', title:'BATTLE ARENA GAMES...',desc:'Australia',
        discord:{title:"discode 用户",value:'4,500+'},
        Scholarship:{title:"奖学金",value:'1,000+'},
        Recruiting:{title:"人员招募",value:'暂无'}, avater:[{avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
          {avater:'/images/center/O ('+ Math.round(Math.random()*10) + ').png'},
        ]},
      ]
    },
    igos:{
      title:"即将推出的IGO",
      list:[
        { image:'/images/center/K5 (1).png', title:'Cyber Arena社区池',desc:'购买阶段开始于'},
        { image:'/images/center/K5 (3).png', title:'CODYFIGHT IGO',desc:'购买阶段开始于'},
        { image:'/images/center/K5 (4).png', title:'ENGINES OF FURY',desc:'购买阶段开始于'},
        { image:'/images/center/K5 (2).png', title:'EVERMOON质押池',desc:'购买阶段开始于'},
        { image:'/images/center/K5 (5).png', title:'Monsterra 社区',desc:'购买阶段开始于'},
        { image:'/images/center/p (3).png', title:'XANA 社区',desc:'购买阶段开始于'},
        { image:'/images/center/p (2).png', title:'X-Metaverse 社区',desc:'购买阶段开始于'},
        { image:'/images/center/p (4).png', title:'MetaSpets 社区',desc:'购买阶段开始于'},
        { image:'/images/center/p (1).png', title:'Drunk Robots 社区',desc:'购买阶段开始于'},
        { image:'/images/center/p (5).png', title:'Rebel Bots 社区',desc:'购买阶段开始于'},
      ]
    }
  },
  SDK: {
      title: "REVO SDK",
      sub_title:`区块链技术让一切皆有可能，例如新的游戏机制、虚拟资产交易、用户生成的内容货币化、新的营销策略，甚至游戏内的财务激励机制很快将以前所未有的轻松方式提供给所有开发者`,
      desc:`我们让游戏开发者能够专注于他们最喜欢和最擅长的事情，即制作具有出色功能的游戏。 Chain X Game 通过处理我们最擅长的事情来做到这一点：处理所有复杂的区块链相关功能，例如：`,
      list:[`透明的区块链账户创建`,`私钥安全和区块链账户恢复`,`访问区块链数据.`,`管理用户的区块链NFT库存`,`方便游戏客户端在区块链上进行交易，无需任何中间人，让用户直接与区块链进行交互`],
      btn: {name: "连接SDK",link: ""},
      overview:{ 
        title:"SDK概览",
        list:[
          {title:"区块链支持",desc:["以太坊","BNB链","Polygon","Solana","ABEY 链"]},
          {title:"NFT工具箱",desc:["游戏内市场","盲盒","NFT合成","NFT质押","NFT借贷"]},
          {title:"Web3用户系统",desc:["Web3帐户创建","Web3推荐系统"]},
          {title:"集成钱包",desc:["Qmnichain支付","跨链SWAP","钱包功能"]},
          {title:"营销工具",desc:["Web3工会系统"]},
        ],
      },
      features: {
        title:"SDK概览",
        list:[
        {title: "多合一登录和身份验证",desc: "使用API和SDK，游戏开发人员无需任何复杂的web3代码即可利用区块链技术的力量",},
        {title: "移动应用钱包集成",desc:`轻松集成所有类型的支付方式。 允许在您自己的市场中使用法定货币和主流加密货币购买NFT资产`,},
        {title: "轻松构建移动NFT市场",desc:"轻松部署移动和网络市场SDK。使用现有模板构建您自己的移动 NFT 市场，具有最佳索引体验（Apple Store 轻松批准）",},
        {title: "零代码发行你的NFT",desc:"一个对开发人员友好的创建者平台，可帮助您在不编写代码的情况下发行、合成和交易 NFT。",},
        {title: "多链支持",desc:"我们支持最流行的 EVM 区块链（Ethereum、BNB Chain、Polygon)，对开发人员没有任何限制 ",},
        {title: "预构建智能合约",desc:  "除了上币、交易等最基本的功能外，您还可以期待以下功能: IGO LaunchPad / 自发行盲盒合约 / NFT借贷",},
        {title: "Walletverse内置",desc: "使用 walletverse SDK 在短短 30 分钟内构建一个强大的区块链内置钱包，并无缝嵌入 Web2 登录、NFT、支付、DAO 和其他应用程序 ",},
        {title: "ABEY链&社区",desc:"凭借高达 3,000 TPS 和低 Gas 费用，ABEYCHAIN 正迅速成为开发人员的选择。用于大批量交易的多层可编程区块链",},
      ]
    },
  },
  footer:{ 
    statement:{ 
      'service':{ title:'服务条款'},
      'policy':{title:'隐私政策'},
      'contact':{title:'联系我们'},
      'rights':{title:'© 2023 Revolution Land. 保留所有权利'},
    }
  }
}