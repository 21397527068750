<template>
  <div class="background">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      block: {},
    };
  },
};
</script>
<style lang="less" scoped>
@keyframes animate {0% {transform: translateY(0) rotate(0deg);opacity: 1;border-radius: 100%;}100% {transform: translateY(-1000px) rotate(720deg);opacity: 0;border-radius: 50%;}}
.background {position: absolute;width: 100%;height: 100%;top: 0;left: 0;margin: 0;padding: 0;overflow: hidden;z-index: 1;}
@keyframes move {100% {transform: translate3d(0, 0, 1px) rotate(360deg);}}.background span {width: 1vmin;height: 1vmin;border-radius: 1vmin;backface-visibility: hidden;position: absolute;animation: move;animation-duration: 49;animation-timing-function: linear;animation-iteration-count: infinite;}
.background span:nth-child(0) {color: #C7FE01;top: 1%;left: 83%;animation-duration: 11s;animation-delay: -1s;transform-origin: -1vw 7vh;box-shadow: -2vmin 0 1.2166831475vmin currentColor;}
.background span:nth-child(1) {color: #C7FE01;top: 77%;left: 30%;animation-duration: 8s;animation-delay: -6s;transform-origin: -16vw -3vh;box-shadow: 2vmin 0 1.0814137563vmin currentColor;}
// .background span:nth-child(2) {color: #A8E510;top: 31%;left: 42%;animation-duration: 11s;animation-delay: -6s;transform-origin: 13vw -16vh;box-shadow: 2vmin 0 0.252018094vmin currentColor;}
.background span:nth-child(3) {color: #7AC125 ;top: 94%;left: 2%;animation-duration: 8s;animation-delay: -8s;transform-origin: -12vw -22vh;box-shadow: 2vmin 0 0.4037830081vmin currentColor;}
.background span:nth-child(4) {color: #7AC125 ;top: 66%;left: 14%;animation-duration: 8s;animation-delay: -5s;transform-origin: 2vw -12vh;box-shadow: 2vmin 0 0.3104758149vmin currentColor;}
// .background span:nth-child(5) {color: #A8E510;top: 30%;left: 22%;animation-duration: 15s;animation-delay: -10s;transform-origin: -24vw -6vh;box-shadow: -2vmin 0 0.5184082025vmin currentColor;}
.background span:nth-child(6) {color: #A8E510;top: 61%;left: 96%;animation-duration: 11s;animation-delay: -8s;transform-origin: -9vw 4vh;box-shadow: -2vmin 0 1.0658300054vmin currentColor;}
.background span:nth-child(7) {color: #A8E510;top: 42%;left: 95%;animation-duration: 9s;animation-delay: -2s;transform-origin: 14vw 7vh;box-shadow: 2vmin 0 0.7873386022vmin currentColor;}
// .background span:nth-child(8) {color: #7AC125 ;top: 30%;left: 69%;animation-duration: 6s;animation-delay: -9s;transform-origin: 9vw 25vh;box-shadow: -2vmin 0 1.2340148539vmin currentColor;}
.background span:nth-child(9) {color: #C7FE01;top: 76%;left: 65%;animation-duration: 7s;animation-delay: -1s;transform-origin: -19vw 11vh;box-shadow: -2vmin 0 0.3706580269vmin currentColor;}
.background span:nth-child(10) {color: #C7FE01;top: 98%;left: 81%;animation-duration: 14s;animation-delay: -10s;transform-origin: 6vw 17vh;box-shadow: 2vmin 0 0.7071063458vmin currentColor;}
// .background span:nth-child(11) {color: #C7FE01;top: 2%;left: 66%;animation-duration: 15s;animation-delay: -4s;transform-origin: 9vw 12vh;box-shadow: -2vmin 0 0.7101778948vmin currentColor;}
.background span:nth-child(12) {color: #7AC125 ;top: 60%;left: 4%;animation-duration: 13s;animation-delay: -10s;transform-origin: -2vw 21vh;box-shadow: 2vmin 0 0.9531848799vmin currentColor;}
.background span:nth-child(13) {color: #A8E510;top: 21%;left: 41%;animation-duration: 14s;animation-delay: -7s;transform-origin: -4vw 22vh;box-shadow: -2vmin 0 0.3326184955vmin currentColor;}
// .background span:nth-child(14) {color: #C7FE01;top: 40%;left: 13%;animation-duration: 14s;animation-delay: -1s;transform-origin: 8vw 25vh;box-shadow: -2vmin 0 0.2889462611vmin currentColor;}
.background span:nth-child(15) {color: #7AC125 ;top: 93%;left: 68%;animation-duration: 10s;animation-delay: -10s;transform-origin: -20vw 7vh;box-shadow: 2vmin 0 1.1570768969vmin currentColor;}
.background span:nth-child(16) {color: #A8E510;top: 28%;left: 16%;animation-duration: 14s;animation-delay: -3s;transform-origin: 4vw -1vh;box-shadow: 2vmin 0 0.3110673443vmin currentColor;}
// .background span:nth-child(17) {color: #7AC125 ;top: 41%;left: 77%;animation-duration: 13s;animation-delay: -8s;transform-origin: -2vw 25vh;box-shadow: 2vmin 0 1.1632744326vmin currentColor;}
.background span:nth-child(18) {color: #A8E510;top: 65%;left: 99%;animation-duration: 7s;animation-delay: -10s;transform-origin: -19vw 24vh;box-shadow: 2vmin 0 0.4234082352vmin currentColor;}
.background span:nth-child(19) {color: #A8E510;top: 99%;left: 33%;animation-duration: 14s;animation-delay: -7s;transform-origin: -19vw 10vh;box-shadow: 2vmin 0 0.6429336872vmin currentColor;}
// .background span:nth-child(20) {color: #A8E510;top: 26%;left: 8%;animation-duration: 12s;animation-delay: -3s;transform-origin: 25vw 21vh;box-shadow: -2vmin 0 0.2895757143vmin currentColor;}
.background span:nth-child(21) {color: #A8E510;top: 62%;left: 2%;animation-duration: 11s;animation-delay: -1s;transform-origin: 3vw -8vh;box-shadow: -2vmin 0 0.648790269vmin currentColor;}
.background span:nth-child(22) {color: #7AC125 ;top: 33%;left: 36%;animation-duration: 10s;animation-delay: -5s;transform-origin: 6vw 24vh;box-shadow: 2vmin 0 0.4563546902vmin currentColor;}
// .background span:nth-child(23) {color: #7AC125 ;top: 52%;left: 7%;animation-duration: 14s;animation-delay: -5s;transform-origin: 24vw 22vh;box-shadow: -2vmin 0 0.9153600755vmin currentColor;}
.background span:nth-child(24) {color: #C7FE01;top: 43%;left: 70%;animation-duration: 15s;animation-delay: -9s;transform-origin: 23vw -10vh;box-shadow: 2vmin 0 0.6479670503vmin currentColor;}
.background span:nth-child(25) {color: #A8E510;top: 1%;left: 29%;animation-duration: 13s;animation-delay: -7s;transform-origin: -10vw -12vh;box-shadow: 2vmin 0 0.5295836513vmin currentColor;}
// .background span:nth-child(26) {color: #7AC125 ;top: 64%;left: 51%;animation-duration: 9s;animation-delay: -7s;transform-origin: 8vw -21vh;box-shadow: 2vmin 0 0.5962699092vmin currentColor;}
.background span:nth-child(27) {color: #A8E510;top: 14%;left: 10%;animation-duration: 14s;animation-delay: -5s;transform-origin: 12vw -11vh;box-shadow: 2vmin 0 0.5281335649vmin currentColor;}
.background span:nth-child(28) {color: #A8E510;top: 57%;left: 100%;animation-duration: 7s;animation-delay: -7s;transform-origin: 23vw -11vh;box-shadow: 2vmin 0 0.2790176444vmin currentColor;}
// .background span:nth-child(29) {color: #C7FE01;top: 60%;left: 34%;animation-duration: 14s;animation-delay: -1s;transform-origin: -18vw 24vh;box-shadow: 2vmin 0 0.5759635814vmin currentColor;}
.background span:nth-child(30) {color: #7AC125 ;top: 45%;left: 14%;animation-duration: 6s;animation-delay: -6s;transform-origin: 19vw 20vh;box-shadow: -2vmin 0 0.7182588918vmin currentColor;}
.background span:nth-child(31) {color: #7AC125 ;top: 42%;left: 44%;animation-duration: 14s;animation-delay: -8s;transform-origin: 4vw -12vh;box-shadow: -2vmin 0 0.3235645262vmin currentColor;}
// .background span:nth-child(32) {color: #7AC125 ;top: 87%;left: 17%;animation-duration: 7s;animation-delay: -8s;transform-origin: 17vw -12vh;box-shadow: 2vmin 0 0.3346880551vmin currentColor;}
.background span:nth-child(33) {color: #C7FE01;top: 77%;left: 96%;animation-duration: 11s;animation-delay: -6s;transform-origin: 18vw -9vh;box-shadow: 2vmin 0 0.7364139192vmin currentColor;}
.background span:nth-child(34) {color: #A8E510;top: 45%;left: 19%;animation-duration: 6s;animation-delay: -9s;transform-origin: 15vw 21vh;box-shadow: 2vmin 0 0.8863952306vmin currentColor;}
// .background span:nth-child(35) {color: #A8E510;top: 10%;left: 20%;animation-duration: 12s;animation-delay: -1s;transform-origin: 25vw 20vh;box-shadow: -2vmin 0 0.3034244133vmin currentColor;}
.background span:nth-child(36) {color: #C7FE01;top: 26%;left: 81%;animation-duration: 14s;animation-delay: -7s;transform-origin: -18vw -8vh;box-shadow: 2vmin 0 1.1515878165vmin currentColor;}
.background span:nth-child(37) {color: #C7FE01;top: 36%;left: 71%;animation-duration: 14s;animation-delay: -2s;transform-origin: 25vw 8vh;box-shadow: -2vmin 0 0.7001679781vmin currentColor;}
// .background span:nth-child(38) {color: #A8E510;top: 47%;left: 56%;animation-duration: 7s;animation-delay: -6s;transform-origin: -16vw -24vh;box-shadow: 2vmin 0 0.9762294148vmin currentColor;}
.background span:nth-child(39) {color: #7AC125 ;top: 13%;left: 59%;animation-duration: 7s;animation-delay: -4s;transform-origin: -19vw 17vh;box-shadow: 2vmin 0 1.1403835373vmin currentColor;}
.background span:nth-child(40) {color: #A8E510;top: 64%;left: 16%;animation-duration: 10s;animation-delay: -6s;transform-origin: 11vw 11vh;box-shadow: 2vmin 0 1.1288142546vmin currentColor;}
// .background span:nth-child(41) {color: #7AC125 ;top: 83%;left: 97%;animation-duration: 12s;animation-delay: -3s;transform-origin: -21vw 11vh;box-shadow: -2vmin 0 0.8546357177vmin currentColor;}
.background span:nth-child(42) {color: #7AC125 ;top: 39%;left: 83%;animation-duration: 10s;animation-delay: -9s;transform-origin: -10vw 7vh;box-shadow: -2vmin 0 0.2694043806vmin currentColor;}
.background span:nth-child(43) {color: #7AC125 ;top: 86%;left: 14%;animation-duration: 10s;animation-delay: -1s;transform-origin: 16vw 11vh;box-shadow: -2vmin 0 0.4910040452vmin currentColor;}
.background span:nth-child(44) {color: #C7FE01;top: 5%;left: 16%;animation-duration: 12s;animation-delay: -10s;transform-origin: 16vw 25vh;box-shadow: -2vmin 0 0.9906608286vmin currentColor;}
.background span:nth-child(45) {color: #7AC125 ;top: 26%;left: 60%;animation-duration: 6s;animation-delay: -4s;transform-origin: 14vw -7vh;box-shadow: -2vmin 0 0.7332913807vmin currentColor;}
.background span:nth-child(46) {color: #7AC125 ;top: 29%;left: 5%;animation-duration: 8s;animation-delay: -3s;transform-origin: 23vw 1vh;box-shadow: -2vmin 0 0.307820715vmin currentColor;}
.background span:nth-child(47) {color: #C7FE01;top: 6%;left: 70%;animation-duration: 14s;animation-delay: -10s;transform-origin: 8vw 12vh;box-shadow: -2vmin 0 0.9688388645vmin currentColor;}
.background span:nth-child(48) {color: #A8E510;top: 97%;left: 37%;animation-duration: 11s;animation-delay: -2s;transform-origin: 25vw 11vh;box-shadow: -2vmin 0 0.6903757254vmin currentColor;}
.background span:nth-child(49) {color: #7AC125 ;top: 62%;left: 66%;animation-duration: 11s;animation-delay: -5s;transform-origin: -4vw -17vh;box-shadow: -2vmin 0 0.8399178073vmin currentColor;}

</style>
