<!--
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-04-11 12:54:04
 * @LastEditTime: 2023-02-14 11:07:59
 * @LastEditors: 叹息桥丶 648161532@qq.com
-->
<template>
  <div class="developer" ref="developer">
    <div id="developer"></div>
    <div class="wrapper safe_content">
      <DeveloperWrapper />
      <div class="content" :class="{ 'animation-slide-left': showfate }">
        <div v-if="info.title" class="title text-green text-bold">
          {{ info.title }}
        </div>
        <div v-if="info.sub_title" class="sub_title text-bold">
          {{ info.sub_title }}
        </div>
        <div v-if="info.desc" class="desc">{{ info.desc }}</div>
        <div class="btn">
          <button class="text-bold" @click="link(info.btn.link)">
            {{ info.btn.title }}
            <div class="sup-bar" v-if="isDao"></div>
          </button>
        </div>
      </div>
      <img :class="{ 'animation-slide-right': showfate }" :src="info.icon" />
    </div>
  </div>
</template>
<script>
import DeveloperWrapper from "@/components/section/developer_wrapper"; //开发商
export default {
  name: "developer",
  components: {
    DeveloperWrapper,
  },
  data() {
    return {
      showfate: true,
    };
  },
  props: {
    info: {
      type: [Object, Array],
      default: function () {
        return {
          title: this.$t("REVO SDK"),
          sub_title: this.$t(
            "REVO SDK is being built to give developers and players the power to embrace the new era of Web3 gaming & Metaverse."
          ),
          desc: this.$t(
            "We want a platform that is easy to use, one that generates new revenue streams for our partners, and one that has powerful tools to accelerate their game development. Today we’re presenting a key part of our solution for game developers, the REVO SDK"
          ),
          btn: {
            title: this.$t("Enter REVO SDK"),
            title_en: this.$t("Contact us"),
            url: "https://www.chainxgame.co.uk/",
            ico: "https://revoland-gw-test.oss-ap-southeast-1.aliyuncs.com/images/sreen6-icon.png",
          },
        };
      },
    },
    isDao: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", this.onscroll, false);
  },
  methods: {
    onscroll() {
      let ele = this.$refs["developer"];
      let top = ele.getBoundingClientRect().top;
      let height = ele.getBoundingClientRect().height;
      this.showfate = top < height;
    },
    link(http) {
      const role = new RegExp("http");
      console.log(http, role.test(http));
      // return false
      if (role.test(http)) {
        // 外联跳转
        window.location.href = http;
      } else {
        this.$router.push(http);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
<style lang="less" scoped>
.developer .content {
  position: relative;
  z-index: 10;
}
.developer {
  background: linear-gradient(90deg, #131313 0%, #22273b 51%, #131313 100%);
}
.safe_content {
  padding-bottom: 80px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 100px;
  height: auto;
  min-height: 600px;
}
.wrapper > img {
  width: 400px;
  aspect-ratio: 1;
}

.wrapper > .content {
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
}
.title {
  font-size: 60px;
  line-height: 1.5;
}
.sub_title {
  font-size: 28px;
  line-height: 1.5;
}
.desc {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}
.btn {
  padding: 30px 0;
  display: flex;
  grid-column-gap: 20px;
}

.text-bold {
  position: relative;
  overflow: hidden;
}

.sup-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-width: 0px 50px 50px 0px;
  border-style: solid;
  border-color: transparent #8966d8 transparent transparent;
}
.sup-bar::after {
  position: relative;
  content: "MAX";
  display: block;
  // color: #000;
  color: #fff;
  padding-top: 2px;
  width: 50px;
  height: 50px;
  font-size: 2px;
  left: 0px;
  top: 0;
  transform: rotate(45deg);
}
.btn > button {
  cursor: pointer;
  width: 360px;
  height: 80px;
  background: linear-gradient(90deg, #fef71d 0%, #c9ff00 100%);
  color: #050020;
  font-size: 28px;
  border-radius: 10px;
}
.btn > button:hover {
  box-shadow: 0px 0px 20px 1px #c9ff00;
}

@media screen and (max-width: 1100px) {
  .app .wrapper > img {
    width: 300px;
  }
  .app .wrapper {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0.3rem 0.2rem 0.3rem;
  }
  .app .title {
    font-size: 0.4rem;
  }
  .app .sub_title {
    font-size: 0.2rem;
  }
  .app .desc {
    font-size: 0.14rem;
  }
  .app .content {
    grid-row-gap: 0.1rem;
  }
  .app .btn {
    padding: 30px 0;
    justify-content: center;
  }
  .app .btn > button {
    width: 2.8rem;
    height: 0.6rem;
    font-size: 0.2rem;
  }
}
</style>
