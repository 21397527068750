/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-06 15:09:43
 * @LastEditTime: 2023-02-03 18:31:24
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Center from "../views/Center.vue";
import SDK from "../views/SDK.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: "/center",
    name: "Center",
    component: Center,
  },
  {
    path: "/sdk",
    name: "SDK",
    component: SDK,
  },
  {
    path: "*",
    name: "404",
    component: Home,
  },
];


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
